import React, { FC } from 'react';
import './sub-heading.scss';

interface SubHeadingProps {
  pageHeading: string;
}

const SubHeading = (props: SubHeadingProps) =>{
 return (
  <div>
    <React.Fragment>
     <div className="subHeading">
                <div className="pageHeading">
                         <h3 style={{ display: "inline-block", marginLeft: "17px"}}> {props.pageHeading}</h3>
                         <h3 style={{ float: "right", marginTop: "9px"}}>PROJECT: <br/>Smart Assist  1.1</h3>
                  </div>
     </div>
     </React.Fragment>
  </div>
);
}

export default SubHeading;
