import React, { Dispatch, FC, SetStateAction, useState, SyntheticEvent, ChangeEvent } from "react";

import "./actionList.scss";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import {
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

export interface Actions {
  description: string,
  actionCategory: string, 
  featureId: string,
}

const ActionsList: FC<{
  setActionList: Dispatch<SetStateAction<any[]>>;
  actionList: Actions[];
  features: any[];
}> = ({ setActionList, actionList, features }) => {
  const [inputBox, setInputBox] = React.useState(false);

  const [linkBox, setLinkBox] = React.useState(false);

  const [conditionalBox, setconditionalBox] = React.useState(false);

  const [textInput, setTextInput] = useState("");

  const [linkInput, setLinkInput] = useState("");

  const [state, setState] = useState("");

  const [actionCategory, setActionCategory] = useState("customer service");

  const [conditionalIntentList, setconditionalIntentList] = useState([
    { feature: "", response: "" },
  ]);

  const handleTextInputChange = (event: ChangeEvent) => {
    console.log("Event ", event);
    setTextInput((event.target as HTMLInputElement ).value);
    setActionCategory("TEXT");
    // setAction(event.target.value);
  };
  const handleLinkInputChange = (event: ChangeEvent) => {
    setLinkInput((event.target as HTMLInputElement ).value);
    setActionCategory("LINK");
  };

  const handleChange = (event: SelectChangeEvent) => {
    if (event.target.value === "deep link") {
      setLinkBox(true);
      setInputBox(false);
      setconditionalBox(false);
    } else if (event.target.value === "inline shot") {
      setInputBox(true);
      setLinkBox(false);
      setconditionalBox(false);
    } else if (event.target.value === "conditional intent") {
      setconditionalBox(true);
      setLinkBox(false);
      setInputBox(false);
    } else if (event.target.value === "text with link") {
      setconditionalBox(false);
      setLinkBox(true);
      setInputBox(true);
    } else {
      setLinkBox(false);
      setInputBox(false);
      setconditionalBox(false);
      setActionCategory("customer service");
    }
  };

  const handleStateChange = (event: SelectChangeEvent) => {
    setState(event.target.value);
  };

  const handleAddClick = () => {
    if (inputBox) {
      setActionList((actionList) => [
        ...actionList,
        {
          description: textInput,
          actionCategory: "TEXT",
          featureId: state,
        },
      ]);
    }
    if (linkBox) {
      setActionList((actionList) => [
        ...actionList,
        {
          description: linkInput,
          actionCategory: "LINK",
          featureId: state,
        },
      ]);
    }
    if (actionCategory === "customer service") {
      setActionList((actionList) => [
        ...actionList,
        {
          description:
            "Please contact customer service for further assistance.",
          actionCategory: "TEXT"
        },
      ]);
      setActionList((actionList) => [
        ...actionList,
        {
          description: "Customer Service=",
          actionCategory: "LINK"
        },
      ]);
    }
    console.log("Action List ", actionList);
  };
  return (
    <Box sx={{ minWidth: 120, paddingTop: "1em", margin: "5px" }}>
      <div style={{  paddingBottom: "1em" }}>
        <div style={{display: "flex"}}>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">State</InputLabel>
          <Select
            // defaultValue="Loyal Customer"
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            required
            label="State"
            onChange={handleStateChange}
          >
            {features.map((feature: any) => (
              <MenuItem value={feature.featureId}>{feature.state}</MenuItem>
            ))}

          </Select>
        </FormControl>
        <br />
        <br />
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Actions</InputLabel>
          <Select
            defaultValue={actionCategory}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            required
            label="Actions"
            onChange={handleChange}
          >
            <MenuItem value={"customer service"}>Customer Service</MenuItem>
            <MenuItem value={"deep link"}>Deep Link</MenuItem>
            <MenuItem value={"inline shot"}>Inline Shot</MenuItem>
            <MenuItem value={"text with link"}>Text with Link</MenuItem>
            <MenuItem value={"conditional intent"}>
              Conditional Intents
            </MenuItem>
          </Select>
        </FormControl>
        <div style={{ width: "3em", paddingLeft: "0px", margin: "auto" }}>
          <Button
            sx={{ marginTop: "12px", height: "0px" }}
            onClick={handleAddClick}
          >
            <AddIcon />
          </Button>
        </div>
        </div>

        <div>
          {inputBox ? (
            <TextField
              autoFocus
              required
              margin="normal"
              name="text"
              label="Enter text"
              type="text"
              variant="standard"
              onChange={handleTextInputChange}
              multiline
              fullWidth
            />
          ) : null}
          {linkBox ? (
            <TextField
              autoFocus
              required
              margin="normal"
              name="link"
              label="Enter link"
              type="link"
              variant="standard"
              onChange={handleLinkInputChange}
              multiline
              fullWidth
            />
          ) : null}
        </div>
      </div>
      {conditionalBox ? (
        <div>
          {conditionalIntentList.map((item, index) => (
            <div key={index} style={{ display: "flex" }}>
              <Grid container spacing={3}>
                <Grid item style={{ width: "36%" }}>
                  <Box sx={{ minWidth: 120, paddingTop: "1em" }}>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        Condition
                      </InputLabel>
                      <Select
                        name="condition"
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        required
                        label="Condition"
                      >
                        <MenuItem value={"card_activated_feature"}>
                          Card Activated
                        </MenuItem>
                        <MenuItem value={"card_activated_not_feature"}>
                          Card not Activated
                        </MenuItem>
                        <MenuItem value={"Overdraft_feture"}>
                          Check eligible for Overdraft
                        </MenuItem>
                        <MenuItem value={"rewards_feature"}>
                          Check eligible for rewards
                        </MenuItem>
                        <MenuItem value={"not_eligible"}>Not eligible</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                </Grid>
                <Grid item style={{ width: "50%" }}>
                  <TextField
                    autoFocus
                    required
                    margin="normal"
                    name="response"
                    label="Response"
                    type="text"
                    variant="standard"
                    onChange={(event) => {
                      const values = [...conditionalIntentList];
                      values[index].response = event.target.value;
                      setconditionalIntentList(values);
                    }}
                    multiline
                    fullWidth
                  />
                </Grid>
                <Grid
                  item
                  style={{ width: "0em", paddingLeft: "0px", margin: "auto" }}
                >
                  {conditionalIntentList.length - 1 === index && (
                    <Button
                      style={{ marginTop: "12px", height: "0px" }}
                      onClick={() => {
                        setconditionalIntentList([
                          ...conditionalIntentList,
                          { feature: "", response: "" },
                        ]);
                      }}
                    >
                      <AddIcon />
                    </Button>
                  )}
                  {conditionalIntentList.length !== 1 && (
                    <Button
                      sx={{ marginTop: "12px", height: "0px" }}
                      onClick={() => {
                        const values = [...conditionalIntentList];
                        values.splice(index, 1);
                        setconditionalIntentList(values);
                      }}
                    >
                      <RemoveIcon />
                    </Button>
                  )}
                </Grid>
              </Grid>
            </div>
          ))}
        </div>
      ) : null}
      <div>
        {actionList.map((item, index) => (
          <div key={index} style={{ display: "flex" }}>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell style={{ width: "14em" }}>
                    <div>{item.description}</div>
                  </TableCell>
                  <TableCell>
                    <div>{item.actionCategory}</div>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </div>
        ))}
      </div>
    </Box>
  );
};

export default ActionsList;
