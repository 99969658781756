import React from "react";
import { Route, Routes } from "react-router-dom";

import Transcript from "./app/modules/transcript/transcript";
import IntentMapping from "./app/modules/intent-mapping/intent-mapping";
import Home from "./app/modules/home/home";
import ComingSoon from "./app/modules/comingSoon/comingSoon";
import PrivateRoutes from '../PrivateRoutes';
import { LoginCallback } from "@okta/okta-react";
import UnknownIntents from "./app/modules/unknownIntents/unknownIntents";


const AppRoutes = () => {
  return (
    <div className="view-routes">
      <Routes>
        <Route element={<PrivateRoutes />}>
          <Route index element={<Home />} />
          <Route path="transcript" element={<Transcript />} />
          <Route path="intentMapping" element={<IntentMapping />} />
          <Route path="comingSoon" element={<ComingSoon />} />
          <Route path="futureIntents" element={<UnknownIntents />} />
        </Route>
        <Route path="/login/callback" element={<LoginCallback />}/>
      </Routes>
    </div>
  );
};

export default AppRoutes;
