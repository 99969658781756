import Header from 'main/app/shared/layout/header/header';
import './home.scss';
import homepageImg from "main/content/images/home-page-image.png";

import React, { Fragment } from 'react';


export const Home = (props: any) => {
  return (
    <Fragment>
      <Header />
      {props.children}
      <div className="app-home">
        <img src={homepageImg} alt="homePage" className="home-page-image"/>
      </div>
    </Fragment>
  );
};

export default Home;
