import React, { FC, Fragment } from 'react';
import Home from '../home/home';
import Header from 'main/app/shared/layout/header/header';
import SubHeading from 'main/app/shared/layout/sub-heading/sub-heading';



const ComingSoon = () => {
   return (
      <Fragment>
         <div>
            <Home><h1 style={{marginTop:'0'}}>Coming Soon</h1></Home>
         </div>
      </Fragment>
   )

}

export default ComingSoon;
