import { Outlet, Navigate } from "react-router-dom";
import { useOktaAuth } from '@okta/okta-react';
const PrivateRoutes = () => {
    const { authState, oktaAuth } = useOktaAuth();
    if(!authState) {
        return <div>Loading...</div>;
    }
   if (!authState.isAuthenticated) oktaAuth.signInWithRedirect();
    return (
        authState.isAuthenticated ? <Outlet /> : <Navigate to="/login/callback"/>
    );
}
export default PrivateRoutes;