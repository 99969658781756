import React, { FC, useEffect, useRef, useState } from "react";
import "./intent-mapping.scss";

import {
  Typography,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  CircularProgress,
  Tooltip,
  InputLabel,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import SubHeading from "../../shared/layout/sub-heading/sub-heading";
import EditNoteOutlinedIcon from "@mui/icons-material/EditNoteOutlined";

import { QueryClient } from "@tanstack/react-query";
import axios from "axios";

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";

import Table from "@mui/material/Table";
import AddIntentForm from "../addIntentForm/addIntentForm";
import Header from "main/app/shared/layout/header/header";

const IntentMapping = () => {
  const [spinner, setSpinner] = useState(false);

  const [responseJson, setResponse] = useState<[]>([]);


  const [features, setFeatures]= useState<{ featureId: string; state: string; featureName: string; userType: string; }[]>([]);

  const [editOpen, setEditOpen] = React.useState(false);

  const editFormData = React.useRef<any>();

  const enableSetFeatureAPI = useRef(true);

  const handleEditButton = (panel: string, i: number) => {
    setEditOpen(true);
    setExpanded(panel);
    editFormData.current = responseJson[i];
  };
  const handleClose = () => {
    setEditOpen(false);
  };

  const Accordion = styled((props: AccordionProps) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
  ))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&::before": {
      display: "none",
    },
  }));
  const AccordionSummary = styled((props: AccordionSummaryProps) => (
    <MuiAccordionSummary
      expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
      {...props}
    />
  ))(({ theme }) => ({
    backgroundColor: "#DCDCDC",
    fontFamily: "Roboto",
    fontWeight: 500,
    fontSize: "15px",
    flexDirection: "row-reverse",
    "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
      transform: "rotate(90deg)",
    },
    "& .MuiAccordionSummary-content": {
      marginLeft: theme.spacing(1),
    },
  }));

  const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: "1px solid rgba(0, 0, 0, .125)",
  }));
  const [, setExpanded] = React.useState<string | false>("panel1");

  const handleChange =
    (panel: string, data: any) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
      if (newExpanded) {
        console.log('expanded');
        let array: any[] = [];
        data.responses.forEach(
          (response: any) => {
            if (!array.includes(response.featureId)) {
              array.push(response.featureId);
            }
          })
          array.forEach((featureId: number) => {
            if (enableSetFeatureAPI.current && featureId) {
              getFeature(featureId);
            }
          });
      } else {
        console.log('collapsed');
        setFeatures([]);
        enableSetFeatureAPI.current = true;
      }
    };

  async function getIntentInfo() {
    setSpinner(true);
    const queryClient = new QueryClient();
    try {
      const data = await queryClient.fetchQuery({
        queryFn: () =>
          axios
            .get("https://pocexl.et.r.appspot.com/intents")
            // .get("http://localhost:8540/chat-transcript/intents")
            .then((res) => {
              setSpinner(false);
              return res.data;
            })
            .catch((error) => {
              console.error(error);
            }),
        staleTime: 10000,
        queryKey: [],
      });

      setResponse(data);

      console.log("Intents data ", data);
    } catch (error) {
      console.log(error);
    }
  }

  async function updateIntentInfo(formJson: any) {
    setSpinner(true);
    const queryClient = new QueryClient();
    try {
      const data = await queryClient.fetchQuery({
        queryFn: () =>
          axios
            .post("https://pocexl.et.r.appspot.com/intents/add", formJson)
            // .post("http://localhost:8540/chat-transcript/intents/add", formJson)
            .then((res) => {
              setSpinner(false);
              return res.data;
            })
            .catch((error) => {
              console.error(error);
            }),
        staleTime: 10000,
        queryKey: [],
      });

      setResponse(data);
      console.log("Update response ", data);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getIntentInfo();
  }, []);

  function splitSamplePhrases(samplePhrases: String) {
    if (samplePhrases !== null && samplePhrases.includes(","))
      return samplePhrases.split(",");
    else {
      const samplePhrasesArray = samplePhrases.split("\n");
      return samplePhrasesArray;
    }
  }

  async function getFeature(featureId: number) {
    const queryClient = new QueryClient();
    try {
      const data = await queryClient.fetchQuery({
        queryFn: () =>
          axios
            .get(
              `https://pocexl.et.r.appspot.com/features/${featureId}`
            )
            .then((res) => {
              // setSpinner(false);
              return res.data;
            })
            .catch((error) => {
              console.error(error);
            }),
        staleTime: 10000,
        queryKey: [],
      });
      setFeatures((prevData: any) => [...prevData, data]);

      enableSetFeatureAPI.current = false;
      console.log("Feature response ", features);
    } catch (error) {
      console.log(error);
    }
  }
  if (spinner) {
    return (
      <div className="spinner">
        <CircularProgress />
      </div>
    );
  }

  return (
    <div className="intent-mapping">
      <Header />
      <div style={{ backgroundColor: "#DCDCDC" }}>
        <SubHeading pageHeading="Intent Mappings" />
      </div>
      <React.Fragment>
        <TableContainer
          sx={{
            marginBottom: "25px",
            height: "calc(100vh - 237px)",
            overflow: "scroll",
            display: "block",
            marginTop: "10px",
            overflowX: "hidden",
          }}
        >
          <Table
            stickyHeader
            aria-label="collapsible sticky table"
            sx={{ marginBottom: "65px" }}
          >
            <TableHead
              sx={{
                "& th": {
                  fontFamily: "Roboto",
                  fontWeight: 900,
                  fontSize: "15px",
                  borderBottom: "1px solid #AFADAD",
                },
              }}
            >
              <TableRow>
                <TableCell>Intent</TableCell>
                <TableCell>
                  Description
                  <AddIntentForm setResponse={setResponse} />
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {responseJson.map((mapResponseData: any, index: number) => (
                <TableRow>
                  <TableCell>
                    {mapResponseData.category}:{mapResponseData.intent}
                  </TableCell>
                  <TableCell>
                    <Accordion onChange={handleChange(`panel${index}`, mapResponseData)}>
                      <AccordionSummary
                        aria-controls="panel1-content"
                        id="panel1-header"
                      >
                        <Typography>{mapResponseData.definition}</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          <div style={{ float: "right" }}>
                            <EditNoteOutlinedIcon
                              onClick={() =>
                                handleEditButton(`panel${index}`, index)
                              }
                            />
                          </div>
                          <Table>
                            <TableRow>
                              <TableCell className="tableCell">
                                <b>Category: </b>
                                <span>{mapResponseData.category}</span>
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell className="tableCell">
                                <b>Keywords: </b>
                                <span>{mapResponseData.keywords}</span>
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell className="tableCell">
                                <b>Sample Phrases:</b>
                              </TableCell>
                              <TableCell className="tableCell">
                                <div style={{ marginRight: "65%" }}>
                                  {splitSamplePhrases(
                                    mapResponseData.samplePhrases
                                  ).map((phrase: string) => (
                                    <>
                                      <span>"{phrase.trim()}"</span>
                                      <br />
                                    </>
                                  ))}
                                </div>
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell className="tableCell">
                                <b>Actions:</b>
                              </TableCell>
                              <TableCell className="tableCell">
                                 { features.map((feature: any) => {
                                    return(
                                      <div>
                                        <span style={{ color: "grey" }}>
                                        {feature?.featureName}:{feature?.state}
                                        </span>
                                        <div>
                                          { mapResponseData.responses.map(
                                            (response: any) => (
                                              <>
                                                {response.featureId ===
                                                  feature?.featureId && (
                                                  <>
                                                    <span
                                                      style={{ color: "red" }}
                                                    >
                                                      {response.description}{" "}
                                                    </span>
                                                    <br />
                                                  </>
                                                )}
                                              </>
                                            )
                                          )} 
                                          <br />
                                        </div>
                                       </div>)
                                }
                                )}
                              </TableCell>
                            </TableRow>
                            <br />
                            <br />
                          </Table>
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Dialog
          open={editOpen}
          onClose={handleClose}
          PaperProps={{
            component: "form",
            onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
              event.preventDefault();
              const formData = new FormData(event.currentTarget);
              const formJson = Object.fromEntries((formData as any).entries());
              formJson.intent = editFormData.current?.intent;
              formJson.category = editFormData.current?.category;
              formJson.responses = editFormData.current?.responses;
              updateIntentInfo(formJson);
              handleClose();
            },
          }}
        >
          <DialogTitle
            style={{
              backgroundColor: "#696969",
              color: "#F0F8FF",
            }}
          >
            {editFormData.current?.category}:{editFormData.current?.intent}{" "}
            Intent Details
          </DialogTitle>
          <DialogContent>
            <Grid container spacing={3}>
              <Grid item>
                <TextField
                  disabled
                  margin="normal"
                  name="category"
                  label="Category"
                  type="text"
                  variant="standard"
                  defaultValue={editFormData.current?.category}
                />
              </Grid>
            </Grid>
            <Grid>
              <TextField
                autoFocus
                required
                margin="normal"
                name="definition"
                label="Description"
                type="text"
                variant="standard"
                multiline
                fullWidth
                defaultValue={editFormData.current?.definition}
              />
            </Grid>
            <TextField
              required
              margin="normal"
              name="keywords"
              label="Keywords"
              type="text"
              fullWidth
              variant="standard"
              defaultValue={editFormData.current?.keywords}
            />
            <Tooltip title="Enter each phrase comma separated">
              <TextField
                required
                margin="normal"
                name="samplePhrases"
                label="Sample Phrases"
                type="text"
                fullWidth
                variant="standard"
                multiline
                defaultValue={editFormData.current?.samplePhrases}
              />
            </Tooltip>
            <InputLabel
              required
              style={{
                lineHeight: "12px",
                fontSize: "smaller",
                paddingTop: "2px",
              }}
            >
              Actions
            </InputLabel>
            {editFormData.current?.responses.map(
              (response: any) =>
                response.description !== null &&
                response.description !== "" && (
                  <>
                    <TextField
                      required
                      margin="normal"
                      name="responses"
                      type="text"
                      fullWidth
                      variant="standard"
                      label={response.actionCategory}
                      defaultValue={response.description}
                      onChange={(e) => (response.description = e.target.value)}
                    />
                  </>
                )
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button type="submit">Save Changes</Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    </div>
  );
};

export default IntentMapping;