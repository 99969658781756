import React, { useState, useEffect} from 'react';
import SubHeading from "../../shared/layout/sub-heading/sub-heading";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Header from "main/app/shared/layout/header/header";
import { CircularProgress } from "@mui/material";
import { JsonViewer } from 'view-json-react';

import { QueryClient } from '@tanstack/react-query';
import axios from 'axios';
import './unknownIntents.scss'; 


const UnknownIntents = () => {

   const [spinner, setSpinner] = useState(false); 

  const[ responseJson, setResponse] = useState([]);

   async function getUnkownIntentsData() {
      setSpinner(true);
      const queryClient = new QueryClient();
      try {
        const data = await queryClient.fetchQuery({
          queryFn: () => axios
            .get('https://pocexl.et.r.appspot.com/unknownMessages')
            .then((res) =>{ 
              setSpinner(false);
              return res.data})
            .catch(error => {
              console.error(error);
            }),
          staleTime: 10000,
          queryKey: []
        });
  
        setResponse(data);
          console.log('Intents data ', data);
      }catch (error) {
        console.log(error)
      }
    }

    useEffect(() => {getUnkownIntentsData()}, []);
  if (spinner) {
    return (
      <div className="spinner">
        <CircularProgress />
        </div>
    );
  }

   return (
      
    <div className="transcript">
    <Header />
    <div style={{ backgroundColor: "#DCDCDC" }}>
      <SubHeading pageHeading="Future Intents" />
    </div>
    <div  className="disclaimer">
             The below table contains the messages that are not recognized by the bot.
             These messages can be used to create new intents and improve the bot's performance.
            </div>
    <TableContainer style={{marginBottom: "25px",
      height: "calc(100vh - 237px)",
      overflow: "scroll",
      display: "block",
      marginTop: "30px",
      overflowX: "hidden",
    }}>
      
      <Table stickyHeader aria-label="collapsible sticky table" sx={{marginBottom: "65px"}}>
        <TableHead
          sx={{
            "& th": {
              fontFamily: "Roboto",
              fontWeight: 900,
              fontSize: "15px",
              borderBottom: "1px solid #AFADAD",
            },
          }}
        >
         
          <TableRow>
            <TableCell> User Message</TableCell>
            <TableCell> ChatBot Version</TableCell>
            <TableCell> LLM Version</TableCell>
            <TableCell> LLM Attributes</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {responseJson.map((mapResponseData: any) => ( 
            <TableRow key={mapResponseData.id}>
              <TableCell>{mapResponseData.message}</TableCell>
              <TableCell>{mapResponseData.chatbotVersion}</TableCell>
              <TableCell>{mapResponseData.llmVersion}</TableCell>
              <TableCell><JsonViewer data= {mapResponseData.llmAttributes} expandLevel={1}/></TableCell>
            </TableRow>
           ))} 
        </TableBody>
      </Table>
    </TableContainer>
  </div>
   );
};

export default UnknownIntents;
