import "./transcript.scss";
import React, { FC, useEffect, useState } from "react";
import SubHeading from "../../shared/layout/sub-heading/sub-heading";
import IosShareIcon from "@mui/icons-material/IosShare";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import { CircularProgress, Typography } from "@mui/material";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary";

import { QueryClient } from '@tanstack/react-query';
import axios from 'axios';

import botImg from "main/content/images/ouro-logo.png";
import userImg from "main/content/images/user.png";
import Header from "main/app/shared/layout/header/header";

const Transcript = () => {
  const [spinner, setSpinner] = useState(false); 

  const[ responseJson, setResponse] = useState<[]>([]);
   console.log("inside compo");

   async function getConversationData() {
    setSpinner(true);
    const queryClient = new QueryClient();
    try {
      const data = await queryClient.fetchQuery({
        queryFn: () => axios
          .get('https://pocexl.et.r.appspot.com/transcripts')
          .then((res) =>{ 
            setSpinner(false);
            return res.data})
          .catch(error => {
            console.error(error);
          }),
        staleTime: 10000,
        queryKey: []
      });

      setResponse(data);
        console.log('Conversation data ', data);
    }catch (error) {
      console.log(error)
    }
  }
  console.log('jsonnn ', responseJson);
   function Row(props: { row: any }) {
    const { row } = props;

    console.log('roww ', row);
    const Accordion = styled((props: AccordionProps) => (
      <MuiAccordion disableGutters elevation={0} square {...props} />
    ))(({ theme }) => ({
      border: `1px solid ${theme.palette.divider}`,
      "&:not(:last-child)": {
        borderBottom: 0,
      },
      "&::before": {
        display: "none",
      },
    }));
    const AccordionSummary = styled((props: AccordionSummaryProps) => (
      <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
        {...props}
      />
    ))(({ theme }) => ({
      backgroundColor: "#DCDCDC",
      fontFamily: "Roboto",
      fontWeight: 500,
      fontSize: "15px",
      flexDirection: "row-reverse",
      "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
        transform: "rotate(90deg)",
      },
      "& .MuiAccordionSummary-content": {
        marginLeft: theme.spacing(1),
      },
    }));

    const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
      padding: theme.spacing(2),
      borderTop: "1px solid rgba(0, 0, 0, .125)",
    }));

    const [, setExpanded] = React.useState<string | false>("panel1");

    const handleChange =
      (panel: string) =>
      (event: React.SyntheticEvent, newExpanded: boolean) => {
        setExpanded(newExpanded ? panel : false);
      };
    function displayIcon(comingFrom: string){
      if(comingFrom === "bot"){
        return(
        <img src={botImg} alt="homePage" className="accordian-image"/>)
      } else if(comingFrom === "user"){
        return(
          <img src={userImg} alt="homePage" className="accordian-image"/>
          )
      }
    }
    
  
    return (
      <React.Fragment>
        <TableRow>
          <TableCell>{row.conversationTimestamp}</TableCell>
          <TableCell>{row.conversationId}</TableCell>
          <TableCell>{row.userId}</TableCell>
          <TableCell component="th" scope="row">
            <Accordion
              onChange={handleChange("panel1")}
            >
              <AccordionSummary
                aria-controls="panel1d-content"
                id="panel1d-header"
              >
                <Typography>Expand to see conversations</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <Table>
                    <TableBody>
                      {row.conversation.map((convoRow:any) => (
                        <React.Fragment>
                          <TableRow>
                            <TableCell>
                              <span style={{margin: "1em"}}>
                                {displayIcon(convoRow.comingFrom)}
                              </span>
                              {convoRow.text}
                              </TableCell>
                          </TableRow>
                        </React.Fragment>
                      ))}
                    </TableBody>
                  </Table>
                </Typography>
              </AccordionDetails>
            </Accordion>
          </TableCell>
          <TableCell>
          <span style={{display: "block"}}>
            <b>ChatBot Version :</b> {row.chatbotVersion}<br/>
            <b>LLM Version :</b>  {row.llmVersion}<br/>
          <b>Origin :</b> {row.origin}
          </span>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }

  

  useEffect(() => {getConversationData()}, []);
  if (spinner) {
    return (
      <div className="spinner">
        <CircularProgress />
        </div>
    );
  }
  return (
    <div className="transcript">
      <Header />
      <div style={{ backgroundColor: "#DCDCDC" }}>
        <SubHeading pageHeading="Transcript" />
        <IosShareIcon style={{ paddingLeft: "60em" }} />
      </div>
      <TableContainer style={{marginBottom: "25px",
        height: "calc(100vh - 237px)",
        overflow: "scroll",
        display: "block",
        marginTop: "10px",
        overflowX: "hidden",
      }}>
        <Table stickyHeader aria-label="collapsible sticky table" sx={{marginBottom: "65px"}}>
          <TableHead
            sx={{
              "& th": {
                fontFamily: "Roboto",
                fontWeight: 900,
                fontSize: "15px",
                borderBottom: "1px solid #AFADAD",
              },
            }}
          >
            <TableRow>
              <TableCell>Date</TableCell>
              <TableCell>CID</TableCell>
              <TableCell>UID</TableCell>
              <TableCell>Conversation</TableCell>
              <TableCell>Detail</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {responseJson.map((row) => (
              <Row row={row} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};
export default Transcript;
