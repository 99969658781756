import React, { Dispatch, FC, SetStateAction, useState } from "react";

import "./addIntentForm.scss";

import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { QueryClient } from "@tanstack/react-query";
import axios from "axios";
import ActionsList from "../actionsList/actionsList";
import{ Actions } from "../actionsList/actionsList";
import {
  Box,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
} from "@mui/material";
const AddIntentForm: FC<{ setResponse: Dispatch<SetStateAction<any>> }> = ({
  setResponse,
}) => {
  const [spinner, setSpinner] = useState(false);
  const [addOpen, setAddOpen] = React.useState(false);
  const [samplePhraseList, setSamplePhraseList] = useState([
    { samplePhrases: "" }
  ]);

  const [features, setFeatures] = useState<{ featureId: string; state: string; featureName: string; userType: string; }[]>([]);
  
  // handle input change
  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number
  ) => {
    const { name, value } = event.target;
    const list: { samplePhrases: string }[] = [...samplePhraseList];
    console.log("List ", list[index]);
    const item = list[index];
    item[name as keyof typeof item] = value;
    setSamplePhraseList(list);
  };

  // handle click event of the Remove button
  const handleRemoveClick = (index: number) => {
    const list = [...samplePhraseList];
    list.splice(index, 1);
    setSamplePhraseList(list);
  };

  // handle click event of the Add button
  const handleAddClick = () => {
    setSamplePhraseList([...samplePhraseList, { samplePhrases: "" }]);
  };
  const handleAddButton = () => {
    setAddOpen(true);
  };
  const handleClose = () => {
    setAddOpen(false);
    setActionList([]);
    setFeatures([]);
  };
 
  const [actionList, setActionList] = useState<Actions[]>([]);
  async function updateIntentInfo(formJson: any) {
    console.log("Action List", actionList);
    formJson.responses= actionList;
    console.log("Form Json ", formJson);
    setSpinner(true);
    const queryClient = new QueryClient();
    try {
      const data = await queryClient.fetchQuery({
        queryFn: () =>
          axios
            .post("https://pocexl.et.r.appspot.com/intents/add", formJson)
            // .post("http://localhost:8540/chat-transcript/intents/add", formJson)
            .then((res) => {
              setSpinner(false);
              return res.data;
            })
            .catch((error) => {
              console.error(error);
            }),
        staleTime: 10000,
        queryKey: [],
      });
      setResponse(data);
      console.log("Update response ", data);
    } catch (error) {
      console.log(error);
    }
  }
  if (spinner) {
    return (
      <div className="spinner">
        <CircularProgress />
      </div>
    );
  }
  async function populateFeature(value: string) {
    const queryClient = new QueryClient();
    try {
      const data = await queryClient.fetchQuery({
        queryFn: () =>
          axios
            .get(
              `https://pocexl.et.r.appspot.com/features?featureName=${value}`
            )
            // .get(
            //   `http://localhost:8540/chat-transcript/features?featureName=${value}`
            // )
            .then((res) => {
              return res.data;
            })
            .catch((error) => {
              console.error(error);
            }),
        staleTime: 10000,
        queryKey: [],
      });
      console.log("Feature data ", data); 
      setFeatures((prevData: any) => [...prevData, ...data]);

    } catch (error) {
      console.log(error);
    }
  }

  return (
    <div>
      <AddIcon
        onClick={handleAddButton}
        style={{ cursor: "pointer",
          right: "1em",
          position: "absolute",
          top: "0em",
      } }
      />
      <Dialog
        open={addOpen}
        onClose={handleClose}
        PaperProps={{
          component: "form",
          onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();
            const formData = new FormData(event.currentTarget);
            const formJson = Object.fromEntries((formData as any).entries());
            formJson.samplePhrases = samplePhraseList
              .map((x) => x.samplePhrases)
              .join(",");
            updateIntentInfo(formJson);
            handleClose();
          },
        }}
      >
        <DialogTitle style={{ backgroundColor: "#696969", color: "#F0F8FF" }}>
          Add Intent Details
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={6}>
            <Grid item style={{width: "50%"}}>
              <TextField
                autoFocus
                required
                margin="normal"
                name="intent"
                label="Intent"
                type="text"
                variant="standard"
                multiline
                fullWidth
              />
            </Grid>
            <Grid item style={{width: "50%"}}>
              <Box sx={{ minWidth: 120, paddingTop: "1em" }}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Category
                  </InputLabel>
                  <Select
                    name="category"
                    required
                    label="Category"
                    onChange={(event) => populateFeature(event.target.value as string)}
                  >
                    <MenuItem value={"Card"}>Card</MenuItem>
                    <MenuItem value={"Customer Service"}>Customer Service</MenuItem>
                    <MenuItem value={"Document"}>Document</MenuItem>
                    <MenuItem value={"FAQ"}>FAQ</MenuItem>
                    <MenuItem value={"Global"}>Global</MenuItem>
                    <MenuItem value={"Notifications"}>Notifications</MenuItem>
                    <MenuItem value={"Overdraft"}>Overdraft</MenuItem>
                    <MenuItem value={"Product Features"}>Product Features</MenuItem>
                    <MenuItem value={"Promotions"}>Promotions</MenuItem>
                    <MenuItem value={"Refer a Friend"}>Refer a Friend</MenuItem>
                    <MenuItem value={"Rewards"}>Rewards</MenuItem>
                    <MenuItem value={"Savings Account"}> Savings Account </MenuItem>
                    <MenuItem value={"Secure Inbox"}>Secure Inbox</MenuItem>
                    <MenuItem value={"Sentiment"}>Sentiment</MenuItem>
                    <MenuItem value={"Smart Assistant"}>Smart Assistant</MenuItem>
                    <MenuItem value={"Spend Account"}>Spend Account</MenuItem>
                    <MenuItem value={"Spending Tracker"}> Spending Tracker </MenuItem>
                    <MenuItem value={"Statement"}>Statement</MenuItem>
                    <MenuItem value={"Transaction"}>Transaction</MenuItem>
                    <MenuItem value={"User Account"}>User Account</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </Grid>
          </Grid>
          <Grid>
            <Tooltip title="Add purpose of intent" placement="top">
              <TextField
                required
                margin="normal"
                name="definition"
                label="Definition"
                type="text"
                variant="standard"
                multiline
                fullWidth
              />
            </Tooltip>
          </Grid>
          <Tooltip
            title="Add comma separated keywords that intent can use."
            placement="top"
          >
            <TextField
              required
              margin="normal"
              name="keywords"
              label="Keywords"
              type="text"
              fullWidth
              variant="standard"
              title=""
            />
          </Tooltip>
          <div>
            {samplePhraseList.map((x, i) => {
              return (
                <div style={{ display: "flex"}}>
                  <TextField
                    required
                    margin="normal"
                    label="Sample Phrase"
                    type="text"
                    fullWidth
                    name="samplePhrases"
                    placeholder="Enter Sample phrase"
                    onChange={(event) => handleInputChange(event, i)}
                  />
                  <div style={{width: "3em", paddingLeft: "0px", margin : "auto"}}>
                    {samplePhraseList.length - 1 === i && (
                      <Button sx={{marginTop: "12px", height: "0px"}} onClick={handleAddClick}>
                        <AddIcon />
                      </Button>
                    )}
                    {samplePhraseList.length !== 1 && (
                      <Button sx={{marginTop: "12px", height: "0px"}} onClick={() => handleRemoveClick(i)}>
                        <RemoveIcon />
                      </Button>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
          
          <ActionsList
            setActionList={setActionList}
            actionList={actionList}
            features={features}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button type="submit">Save Changes</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
export default AddIntentForm;
