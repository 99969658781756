import './footer.scss';

const Footer = () => (
  <div className="footer page-content">
   The data over the website is experimental. Please expect some latency for the server to response. 
   While we aim for accuracy, the tool may produce errors or incomplete information.
  </div>
);

export default Footer;
