import './header.scss';

import React, { useState } from 'react';

import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useNavigate } from 'react-router-dom';

import logoImg from "main/content/images/ouro-logo.png";
import brandName from "main/content/images/ouro-logo-white.png";
import bellImg from "main/content/images/bell.png";
import Footer from '../footer/footer';
import { withOktaAuth } from '@okta/okta-react';


const Header = (props: any) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const navigate = useNavigate();
  const handleClose = (param:any) => {
  console.log('inside handleclose', param);
    setAnchorEl(null);
    if (typeof param === 'string') navigate(param);
  };


  //OKTA logout
  const logout = () => {
    props.oktaAuth.signOut();
  }

  return (
    <div id="app-header">

       <div className="top-nav">
            <Button
              id="demo-positioned-button"
              aria-controls={open ? 'demo-positioned-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              onClick={handleClick}
            >
              <img src={logoImg} alt="Logo"
                                className="icon"/>
            </Button>
            <div className="popup-menu">
            <Menu
              id="demo-positioned-menu"
              aria-labelledby="demo-positioned-button"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
            >
              <div style={{ backgroundColor: "tan", padding: "10px"}}><b>PROJECT:<br/> Smart Assistant 1.1</b></div>
              <MenuItem onClick={() => handleClose('/intentMapping')}>Intent Mappings</MenuItem>
              <MenuItem onClick={() => handleClose('/transcript')}>Transcripts</MenuItem>
              <MenuItem onClick={() => handleClose('/futureIntents')}>Future Intents</MenuItem>
              <MenuItem onClick={() => handleClose('/comingSoon')}>Reports</MenuItem>
              <MenuItem onClick={() => handleClose('/comingSoon')}>Workflow</MenuItem>
              <MenuItem onClick={logout}>Logout</MenuItem>
            </Menu>
            <div className="verticalLine"></div>
            </div>
            <div>
              <img src={brandName} alt="Logo" onClick={() => handleClose('')}
                                              className="icon"/>
                        <div className="app-name">Smart Assistant Admin Console</div>
             </div>
              <img src={bellImg} alt="notifications-icon"
                                                            className="bell-icon"/>

          </div>
          <Footer />
    </div>
  );
};

export default withOktaAuth(Header)
